<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      width?: number | string
      height?: number | string
      color?: string
      viewBoxValue?: string
    }>(),
    {
      width: 24,
      height: 24,
      color: 'currentColor',
      viewBoxValue: `0 0 24 24`
    }
  )
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="props.viewBoxValue"
  >
    <g fill="none">
      <slot />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
  svg {
    display: inline-block;
    vertical-align: baseline;
  }
</style>
